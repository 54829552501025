import React,{useEffect} from "react";
import "./Login.css";
import loginPlaceholder from "../../assets/images/asset-1.png";
import {Button} from "antd";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import {useHistory} from "react-router-dom";
import {useMsal, useIsAuthenticated} from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const Login = () => {

	const history = useHistory();
	const { instance } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	const handleLogin = () => {
		instance.loginRedirect(loginRequest).then(r => {
			console.log('msal log',r)
		}).catch();
	}

	useEffect(()=>{
		isAuthenticated && history.push(appRoutes.PODCASTS)
	},[isAuthenticated])

	return (
		<div className="app-wrapper login">
			<div className='login__container'>
				<div className='login__column1'>
                    <div className='login__content'>
						Welcome to the University of Rochester Podcasting Network.
					</div>
					<Button type={'primary'}
							onClick={handleLogin}
							className='login__action'
					>
						SIGN IN
					</Button>
				</div>
				<div className='login__column2'>
					<img src={loginPlaceholder} alt='' className='login__placeholder'/>
				</div>
			</div>
		</div>
	);
};

export default Login;
